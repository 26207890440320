// Code generated by go run pkg/apigen/generate. DO NOT EDIT.

import useSWR, { SWRConfiguration, SWRResponse } from "swr";

import * as enums from "~src/shared/enums";
import * as routerTypes from "~src/user/apigen/types";
import * as globalTypes from "~src/shared/apigen/types";
import { fetchRPCRoute } from "~src/shared/apigen/framework";
import { FieldValidationError, RPCError, IRPCErrorOptions } from "~src/shared/errors/taxonomy";

export type IUserCreateRoomRequest = {
  type: enums.IRoomTypeEnum;
  base: number | null;
  maxMultiplier: number | null;
};

export type IUserCreateRoomReturn = Record<string, never>;

export const userCreateRoom = async (
  args: IUserCreateRoomRequest,
): Promise<IUserCreateRoomReturn> => {
  return await fetchRPCRoute({
    method: "POST",
    router: "user",
    routePath: "CreateRoom",
    args,
    errors: [
      { code: "USER/CREATE_ROOM/NOT_ALLOWED", ErrorClass: UserCreateRoomNotAllowed },
    ],
  });
};

export const useUserCreateRoom = (
  args: IUserCreateRoomRequest,
  config?: SWRConfiguration & {
    // If true, the hook will not fetch. Data will be null.
    blockFetch?: boolean,
    // This value will be included in the cache key. Pass different values to force cache
    // invalidation.
    refetchNonce?: number | string,
  },
): SWRResponse<IUserCreateRoomReturn, RPCError> => {
  return useSWR(
    config?.blockFetch === true ? null : ["/user/CreateRoom", args, config?.refetchNonce],
    async (_, _args) => await userCreateRoom(_args),
    config,
  );
};

export class UserCreateRoomNotAllowed extends RPCError {
  static code = "USER/CREATE_ROOM/NOT_ALLOWED";
  constructor(message: string, options: IRPCErrorOptions) {
    super(message, {
      ...options,
      expected: true,
      transient: false,
      status: 400,
    });
  }
}
