// Code generated by go run pkg/apigen/generate. DO NOT EDIT.

import useSWR, { SWRConfiguration, SWRResponse } from "swr";

import * as enums from "~src/shared/enums";
import * as routerTypes from "~src/user/apigen/types";
import * as globalTypes from "~src/shared/apigen/types";
import { fetchRPCRoute } from "~src/shared/apigen/framework";
import { FieldValidationError, RPCError, IRPCErrorOptions } from "~src/shared/errors/taxonomy";

export type IGetRoomsRoom = {
  id: number;
  userCount: number;
  type: enums.IRoomTypeEnum;
  baseAmount: number;
  maxMultiplier: number | null;
};

export type IUserGetRoomsReturn = {
  rooms: IGetRoomsRoom[];
};

export const userGetRooms = async (
): Promise<IUserGetRoomsReturn> => {
  return await fetchRPCRoute({
    method: "POST",
    router: "user",
    routePath: "GetRooms",
    args: undefined,
    errors: [
    ],
  });
};

export const useUserGetRooms = (
  config?: SWRConfiguration & {
    // If true, the hook will not fetch. Data will be null.
    blockFetch?: boolean,
    // This value will be included in the cache key. Pass different values to force cache
    // invalidation.
    refetchNonce?: number | string,
  },
): SWRResponse<IUserGetRoomsReturn, RPCError> => {
  return useSWR(
    config?.blockFetch === true ? null : ["/user/GetRooms", config?.refetchNonce],
    userGetRooms,
    config,
  );
};
