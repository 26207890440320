// Code generated by go run pkg/apigen/generate. DO NOT EDIT.

import useSWR, { SWRConfiguration, SWRResponse } from "swr";

import * as enums from "~src/shared/enums";
import * as routerTypes from "~src/user/apigen/types";
import * as globalTypes from "~src/shared/apigen/types";
import { fetchRPCRoute } from "~src/shared/apigen/framework";
import { FieldValidationError, RPCError, IRPCErrorOptions } from "~src/shared/errors/taxonomy";

export type IUserJoinRoomRequest = {
  roomID: number;
};

export type IUserJoinRoomReturn = Record<string, never>;

export const userJoinRoom = async (
  args: IUserJoinRoomRequest,
): Promise<IUserJoinRoomReturn> => {
  return await fetchRPCRoute({
    method: "POST",
    router: "user",
    routePath: "JoinRoom",
    args,
    errors: [
    ],
  });
};

export const useUserJoinRoom = (
  args: IUserJoinRoomRequest,
  config?: SWRConfiguration & {
    // If true, the hook will not fetch. Data will be null.
    blockFetch?: boolean,
    // This value will be included in the cache key. Pass different values to force cache
    // invalidation.
    refetchNonce?: number | string,
  },
): SWRResponse<IUserJoinRoomReturn, RPCError> => {
  return useSWR(
    config?.blockFetch === true ? null : ["/user/JoinRoom", args, config?.refetchNonce],
    async (_, _args) => await userJoinRoom(_args),
    config,
  );
};
