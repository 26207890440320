// Code generated by go run pkg/apigen/generate. DO NOT EDIT.

import useSWR, { SWRConfiguration, SWRResponse } from "swr";

import * as enums from "~src/shared/enums";
import * as routerTypes from "~src/public/apigen/types";
import * as globalTypes from "~src/shared/apigen/types";
import { fetchRPCRoute } from "~src/shared/apigen/framework";
import { FieldValidationError, RPCError, IRPCErrorOptions } from "~src/shared/errors/taxonomy";

export type IPublicDiscordOAuthRequest = {
  code: string;
};

export type IPublicDiscordOAuthReturn = Record<string, never>;

export const publicDiscordOAuth = async (
  args: IPublicDiscordOAuthRequest,
): Promise<IPublicDiscordOAuthReturn> => {
  return await fetchRPCRoute({
    method: "POST",
    router: "public",
    routePath: "DiscordOAuth",
    args,
    errors: [
    ],
  });
};

export const usePublicDiscordOAuth = (
  args: IPublicDiscordOAuthRequest,
  config?: SWRConfiguration & {
    // If true, the hook will not fetch. Data will be null.
    blockFetch?: boolean,
    // This value will be included in the cache key. Pass different values to force cache
    // invalidation.
    refetchNonce?: number | string,
  },
): SWRResponse<IPublicDiscordOAuthReturn, RPCError> => {
  return useSWR(
    config?.blockFetch === true ? null : ["/public/DiscordOAuth", args, config?.refetchNonce],
    async (_, _args) => await publicDiscordOAuth(_args),
    config,
  );
};
