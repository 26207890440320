// Code generated by go run pkg/apigen/generate. DO NOT EDIT.

import useSWR, { SWRConfiguration, SWRResponse } from "swr";

import * as enums from "~src/shared/enums";
import * as routerTypes from "~src/user/apigen/types";
import * as globalTypes from "~src/shared/apigen/types";
import { fetchRPCRoute } from "~src/shared/apigen/framework";
import { FieldValidationError, RPCError, IRPCErrorOptions } from "~src/shared/errors/taxonomy";

export type IUserCreateTurnRequest = {
  selectedCards: number[];
};

export type IUserCreateTurnReturn = Record<string, never>;

export const userCreateTurn = async (
  args: IUserCreateTurnRequest,
): Promise<IUserCreateTurnReturn> => {
  return await fetchRPCRoute({
    method: "POST",
    router: "user",
    routePath: "CreateTurn",
    args,
    errors: [
      { code: "USER/CREATE_TURN/INVALID", ErrorClass: UserCreateTurnInvalid },
    ],
  });
};

export const useUserCreateTurn = (
  args: IUserCreateTurnRequest,
  config?: SWRConfiguration & {
    // If true, the hook will not fetch. Data will be null.
    blockFetch?: boolean,
    // This value will be included in the cache key. Pass different values to force cache
    // invalidation.
    refetchNonce?: number | string,
  },
): SWRResponse<IUserCreateTurnReturn, RPCError> => {
  return useSWR(
    config?.blockFetch === true ? null : ["/user/CreateTurn", args, config?.refetchNonce],
    async (_, _args) => await userCreateTurn(_args),
    config,
  );
};

export class UserCreateTurnInvalid extends RPCError {
  static code = "USER/CREATE_TURN/INVALID";
  constructor(message: string, options: IRPCErrorOptions) {
    super(message, {
      ...options,
      expected: true,
      transient: false,
      status: 400,
    });
  }
}
