import CSS from "csstype";
import React from "react";
import { IGetStateCard, IUserGetStateReturn } from "~src/user/apigen/getState";

type HandProps = {
  state: IUserGetStateReturn;
  toggleCard: (id: number) => void;
  selectedCards: number[];
};

export const Hand: React.FC<HandProps> = ({ state, selectedCards, toggleCard }) => {
  if (!state.gameIsActive) {
    return null;
  }

  const showCard = (state: IUserGetStateReturn, card: IGetStateCard) => {
    return state.shownCard.id === card.id && state.turn === 0 && state.landlordHandID === null;
  };

  return (
    <div style={hand}>
      {state?.cards.map(card => {
        return (
          <span key={card.id} onClick={() => toggleCard(card.id)}>
            {(selectedCards.includes(card.id) || showCard(state, card)) && (
              <span style={cardStyle(window.innerWidth < 786, true)}>{card.value}</span>
            )}
            {!selectedCards.includes(card.id) && !showCard(state, card) && (
              <span style={cardStyle(window.innerWidth < 786, false)}>{card.value}</span>
            )}
          </span>
        );
      })}
    </div>
  );
};

const hand: CSS.Properties = {
  position: "absolute",
  bottom: "10%",
  left: "50%",
  transform: "translateX(-50%)",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "1.5em",
};

const cardStyle = (smallScreen: boolean, selected: boolean): CSS.Properties => ({
  margin: smallScreen ? "4px" : "5px",
  position: "relative",
  display: "block",
  cursor: "pointer",
  transform: selected ? "translateY(-75%)" : "",
});
