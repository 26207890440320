import CSS from "csstype";
import React from "react";
import { formatAmount } from "~src/shared/helpers/format";
import { IGetStateHand } from "~src/user/apigen/getState";
import { StateProps } from "./Center";

export const Balance: React.FC<StateProps> = ({ state }) => {
  if (state === undefined) {
    return null;
  }

  const person = state.hands.filter((user: IGetStateHand) => {
    return user.position === state.position;
  })[0];

  if (person === undefined) {
    return null;
  }

  return (
    <div style={balance(window.innerWidth < 786)}>
      <div style={header(window.innerWidth < 786)}>balance</div>
      {formatAmount(person.roomBalance)}
    </div>
  );
};


const balance = (smallScreen: boolean): CSS.Properties => ({
  position: "absolute",
  margin: smallScreen ? "30px" : "50px",
  bottom: "0",
  right: "0",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  fontSize: "1.5em",
});

const header = (smallScreen: boolean): CSS.Properties => ({
  fontSize: ".7em",
  marginBottom: smallScreen ? "9px" : "15px",
});