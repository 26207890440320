// Code generated by go run pkg/apigen/generate. DO NOT EDIT.

import useSWR, { SWRConfiguration, SWRResponse } from "swr";

import * as enums from "~src/shared/enums";
import * as routerTypes from "~src/user/apigen/types";
import * as globalTypes from "~src/shared/apigen/types";
import { fetchRPCRoute } from "~src/shared/apigen/framework";
import { FieldValidationError, RPCError, IRPCErrorOptions } from "~src/shared/errors/taxonomy";

export type IUserGetStatsReturn = {
  gamesPlayed: number;
  gamesWon: number;
  pointsPerGame: number;
  bombs: number;
};

export const userGetStats = async (
): Promise<IUserGetStatsReturn> => {
  return await fetchRPCRoute({
    method: "POST",
    router: "user",
    routePath: "GetStats",
    args: undefined,
    errors: [
    ],
  });
};

export const useUserGetStats = (
  config?: SWRConfiguration & {
    // If true, the hook will not fetch. Data will be null.
    blockFetch?: boolean,
    // This value will be included in the cache key. Pass different values to force cache
    // invalidation.
    refetchNonce?: number | string,
  },
): SWRResponse<IUserGetStatsReturn, RPCError> => {
  return useSWR(
    config?.blockFetch === true ? null : ["/user/GetStats", config?.refetchNonce],
    userGetStats,
    config,
  );
};
