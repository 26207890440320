import CSS from "csstype";
import React from "react";
import { StateProps } from "./Center";

export const LeftTurnSignal: React.FC<StateProps> = ({ state }) => {
  if (state.position === 1 && state.turn === 0 && state.landlordHandID === null) {
    return (
      <div style={leftTurnSignal}>
        <span>{state.shownCard.value}</span>
      </div>
    );
  }

  if (state.lastTwoTurns.length < 1) {
    return null;
  }

  if (state.position === state.turn) {
    if (state.lastTwoTurns[0] !== undefined && state.lastTwoTurns[0].length > 0) {
      return (
        <div style={leftTurnSignal}>
          <span>&rarr;</span>
        </div>
      );
    }
    if (state.lastTwoTurns[0] !== undefined && state.lastTwoTurns[0].length === 0) {
      return (
        <div style={leftTurnSignal}>
          <span>pass</span>
        </div>
      );
    }
  }

  if ((state.position + 1) % 3 === state.turn) {
    if (state.lastTwoTurns[0] !== undefined && state.lastTwoTurns[0].length > 0) {
      return null;
    }

    if (state.lastTwoTurns[1] !== undefined && state.lastTwoTurns[1].length > 0) {
      return (
        <div style={leftTurnSignal}>
          <span>&rarr;</span>
        </div>
      );
    }
    if (state.lastTwoTurns[1] !== undefined && state.lastTwoTurns[1].length === 0) {
      return (
        <div style={leftTurnSignal}>
          <span>pass</span>
        </div>
      );
    }
  }

  return null;
};

const leftTurnSignal: CSS.Properties = {
  position: "absolute",
  top: "50%",
  left: "25%",
  transform: "translateX(-50%)",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "1.5em",
};
