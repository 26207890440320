import CSS from "csstype";
import React from "react";
import { formatAmount } from "~src/shared/helpers/format";
import { IGetStateHand } from "~src/user/apigen/getState";
import { StateProps } from "./Center";

export const LeftPerson: React.FC<StateProps> = ({ state }) => {
  if (state.hands.length === 0) {
    return null;
  }

  const person = state.hands.filter((user: IGetStateHand) => {
    return user.position === (state.position + 2) % 3;
  })[0];

  if (person === undefined) {
    return null;
  }

  return (
    <div style={leftPersonStyle(window.innerWidth < 786)}>
      {person.cardsLeft !== null && person.cardsLeft !== undefined && (
        <div>
          {person.cardsLeft + " card" + (person.cardsLeft === 2 ? "s" : "")}
        </div>
      )}
      {person.dou === true && state.showMiddleCards === true && (
        <div>
          dou
        </div>
      )}
      <div>
        {person.displayName}
      </div>
      <div>
        {formatAmount(person.roomBalance)}
      </div>
      {person.handID === state.landlordHandID && (
        <div>
          L
        </div>
      )}
    </div>
  );
};

const leftPersonStyle = (smallScreen: boolean): CSS.Properties => ({
  position: "absolute",
  top: "45%",
  left: smallScreen ? "30px" : "50px",
  justifyContent: "left",
  alignItems: "left",
  fontSize: "1.5em",
});
