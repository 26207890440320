import CSS from "csstype";
import max from "lodash/max";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { IMessage } from "~src/shared/apigen/types";
import { userGetMessages } from "~src/user/apigen/getMessages";
import { type IUserSendMessageReturn, userSendMessage } from "~src/user/apigen/sendMessage";

type ChatProps = {
  chatOpen: boolean;
  setChatOpen: (open: boolean) => void;
};

export const Chat: React.FC<ChatProps> = ({ chatOpen, setChatOpen }) => {
  const lastSeenMessageID = useRef<number | null>(null);
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState<IMessage[]>([]);

  const messagesRef = useRef<HTMLDivElement>(null);
  const scrollHookRef = useRef<HTMLDivElement>(null);

  // We want to scroll down after new messages are added, but that must happen after a rerender.
  // This means we cannot put it into the state updater because the rerender only occurs after state
  // updates are finished. When this variable is bumped, we scroll to bottom.
  const [scrollBumper, setScrollBumper] = useState(0);
  useEffect(() => {
    if (scrollHookRef.current != null) {
      console.log("HIT");
      scrollHookRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [scrollBumper]);

  const storeNewMessages = useCallback((newMessages: IMessage[]): void => {
    setMessages(oldMessages => {
      const messageIDs = new Set(oldMessages.map(x => x.id));
      const dedupedMessages = newMessages.filter(x => !messageIDs.has(x.id));
      return [...oldMessages, ...dedupedMessages];
    });
    lastSeenMessageID.current = max([lastSeenMessageID.current, ...newMessages.map(x => x.id)])
      ?? null;

    /* https://stackoverflow.com/a/42860948 */
    const atBottom = messagesRef.current != null && messagesRef.current.scrollHeight - messagesRef.current.scrollTop - messagesRef.current.clientHeight < 1;
    if (atBottom) {
      setScrollBumper(x => x + 1);
    }
  }, [setMessages]);

  // Fetch messages every second and deduplicate with the stored messages. Only do this when chat is
  // open.
  useEffect(() => {
    if (!chatOpen) {
      return;
    }
    const interval = setInterval(async () => {
      const resp = await userGetMessages({ afterID: lastSeenMessageID.current });
      if (resp.messages.length !== 0) {
        storeNewMessages(resp.messages);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [chatOpen, storeNewMessages]);

  const sendMessage = async (e: any) => {
    e.preventDefault();

    if (!input) {
      return;
    }
    let resp: IUserSendMessageReturn;
    try {
      resp = await userSendMessage({
        contents: input,
        lastSeenMessageID: lastSeenMessageID.current,
      });
    } catch (e) {
      console.error(`Failed to send message: ${e}`);
      return;
    }
    setInput("");
    if (resp.newMessages.length !== 0) {
      storeNewMessages(resp.newMessages);
    }
  };

  if (!chatOpen) {
    return null;
  }

  return (
    <div style={layoutStyle}>
      <div role="button" onClick={() => setChatOpen(false)} style={closeButtonStyle}>
        chat {">>"}
      </div>
      <div ref={messagesRef} className="readable-font" style={messagesListStyle}>
        {messages.map(m => (
          <div
            key={m.id}
            style={{ display: "flex", width: "100%", alignItems: "baseline", minWidth: "0" }}
          >
            <div style={{ flex: "0 0", color: "#888", fontSize: "0.5rem" }}>
              {new Date(m.createdAt).getHours().toString().padStart(2, "0")}
              :{new Date(m.createdAt).getMinutes().toString().padStart(2, "0")}
              :{new Date(m.createdAt).getSeconds().toString().padStart(2, "0")}
              &nbsp;
            </div>
            <div style={{ flex: "1 1 100%", hyphens: "auto" }}>
              {"<" + m.sentBy + "> "}
              {m.contents.length > 88 ? m.contents.slice(0, 88) + "..." : m.contents}
            </div>
          </div>
        ))}
        <div ref={scrollHookRef} />
      </div>
      <form style={inputPairStyle} onSubmit={sendMessage}>
        <input
          type="text"
          placeholder="Send a message..."
          className="readable-font"
          value={input}
          onChange={e => setInput(e.target.value)}
          style={inputStyle}
        />
        <button type="submit" style={sendButtonStyle}>send</button>
      </form>
    </div>
  );
};

const layoutStyle: CSS.Properties = {
  position: 'absolute',
  borderLeft: "2px solid #444",
  height: "100%",
  width: "20%",
  right: '0px',
  display: "flex",
  flexDirection: "column",
};

const closeButtonStyle: CSS.Properties = {
  padding: "8px",
  width: "fit-content",
  cursor: "pointer",
};

const messagesListStyle: CSS.Properties = {
  display: "flex",
  flexDirection: "column",
  gap: "2px",
  fontSize: "0.6rem",
  padding: "8px",
  borderTop: "2px solid #444",
  flex: "1 1 100%",
  overflowY: "auto",
};

const inputPairStyle: CSS.Properties = {
  display: "flex",
  flexDirection: "row",
  borderTop: "2px solid #444",
  height: "48px",
  minWidth: 0,
  width: "100%",
};

const inputStyle: CSS.Properties = {
  flex: "1 1 100%",
  height: "100%",
  fontSize: "0.6rem",
  border: "none",
  padding: "0 8px",
  background: "#181818",
  color: "#bbb",
};

const sendButtonStyle: CSS.Properties = {
  background: "#181818",
  color: "#bbb",
  height: "100%",
  flex: "0 0 fit-content",
  fontSize: "0.7rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0 8px",
  borderTop: "none",
  borderBottom: "none",
  borderRight: "none",
  borderLeft: "2px solid #444",
  cursor: "pointer",
  fontFamily: "ChineseTakeaway",
};
