// Code generated by go run pkg/apigen/generate. DO NOT EDIT.

import useSWR, { SWRConfiguration, SWRResponse } from "swr";

import * as enums from "~src/shared/enums";
import * as routerTypes from "~src/admin/apigen/types";
import * as globalTypes from "~src/shared/apigen/types";
import { fetchRPCRoute } from "~src/shared/apigen/framework";
import { FieldValidationError, RPCError, IRPCErrorOptions } from "~src/shared/errors/taxonomy";

export type IAdminAddLedgerEntryRequest = {
  userID: number;
  amount: number;
};

export type IAdminAddLedgerEntryReturn = Record<string, never>;

export const adminAddLedgerEntry = async (
  args: IAdminAddLedgerEntryRequest,
): Promise<IAdminAddLedgerEntryReturn> => {
  return await fetchRPCRoute({
    method: "POST",
    router: "admin",
    routePath: "AddLedgerEntry",
    args,
    errors: [
    ],
  });
};

export const useAdminAddLedgerEntry = (
  args: IAdminAddLedgerEntryRequest,
  config?: SWRConfiguration & {
    // If true, the hook will not fetch. Data will be null.
    blockFetch?: boolean,
    // This value will be included in the cache key. Pass different values to force cache
    // invalidation.
    refetchNonce?: number | string,
  },
): SWRResponse<IAdminAddLedgerEntryReturn, RPCError> => {
  return useSWR(
    config?.blockFetch === true ? null : ["/admin/AddLedgerEntry", args, config?.refetchNonce],
    async (_, _args) => await adminAddLedgerEntry(_args),
    config,
  );
};
