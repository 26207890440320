import CSS from "csstype";
import React, { useCallback, useEffect, useState } from "react";
import { publicDiscordOAuth } from "~src/public/apigen/discordOAuth";
import { publicSignIn } from "~src/public/apigen/signIn";

type SignInProps = {
  refetchUser: () => void;
};

export const SignIn: React.FC<SignInProps> = ({ refetchUser }) => {
  const [name, setName] = useState("");

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const signIn = useCallback(async () => {
    await publicSignIn({ name });
    refetchUser();
  }, [name, refetchUser]);

  const redirect = useCallback(async () => {
    window.location.href = discordURL();
  }, []);

  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get("code");

  const discord = useCallback(async (code: string) => {
    await publicDiscordOAuth({ code });
    window.history.pushState({}, document.title, "/");
    refetchUser();
  }, [refetchUser]);

  useEffect(() => {
    if (code !== null) {
      discord(code);
    }
  }, [code, discord]);

  if (code !== null) {
    return null;
  }

  return (
    <div className="readable-font" style={grid}>
      <div style={{ display: "flex", alignItems: "end" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
          <label htmlFor="name" style={label}>
            name
          </label>
          <input id="name" style={inputStyle} type="text" onChange={handleNameChange} />
        </div>
        <button style={playStyle} onClick={signIn}>
          play &rarr;
        </button>
      </div>
      <div>
        <button style={discordStyle} onClick={redirect}>
          or sign in with discord
        </button>
      </div>
    </div>
  );
};

const buttonsStyle: CSS.Properties = {
  padding: "5px 10px",
  border: "none", /* Remove default border */
  borderRadius: "5px", /* Rounded corners */
  cursor: "pointer", /* Cursor changes to pointer to indicate it's clickable */
  fontSize: "18px",
  transition: "background-color 0.3s, transform 0.2s", /* Smooth background and press effect */
};

const inputStyle: CSS.Properties = {
  padding: "5px 10px",
  fontSize: "18px",
  border: "none",
  borderRadius: "5px",
};

const playStyle: CSS.Properties = {
  ...buttonsStyle,
  marginLeft: "10px",
  whiteSpace: "nowrap",
};

const discordStyle: CSS.Properties = {
  ...buttonsStyle,
  background: "#7289da",
  marginTop: "10px",
};

const grid: CSS.Properties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  justifyContent: "center",
};

const label: CSS.Properties = {
  fontSize: ".7em",
};

const discordURL = () => {
  if (window.location.hostname === "localhost") {
    return "https://discord.com/api/oauth2/authorize?client_id=1201624873156497459&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A3000&scope=identify+guilds.join";
  }
  return "https://discord.com/api/oauth2/authorize?client_id=1201624873156497459&response_type=code&redirect_uri=https%3A%2F%2Fwww.doudizhu.online&scope=identify+guilds.join";
};

// export const SignIn: React.FC<SignInProps> = ({refetchUser}) => {
//   const redirect = useCallback(async () => {
//     // window.location.href = 'https://discord.com/oauth2/authorize?response_type=code&client_id=1201624873156497459&scope=identify%20guilds.join&state=tbd&redirect_uri=https%3A%2F%2Fdoudizhu.online&prompt=consent'
//     window.location.href = 'https://discord.com/api/oauth2/authorize?client_id=1201624873156497459&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A3000&scope=identify+guilds.join'
//   },[]);

//   const queryParams = new URLSearchParams(window.location.search);
//   const code = queryParams.get('code');

//   const discord = useCallback(async (code: string) => {
//     await publicDiscordCode({code})
//     window.history.pushState({}, document.title, '/')
//     refetchUser()
//   },[refetchUser])

//   useEffect(()=> {
//     if (code !== null) {
//       discord(code)
//     }
//   },[code, discord])

//   return (
//     <div style={grid}>
//       <div onClick={redirect}>
//         discord
//       </div>
//     </div>
//   );
// };
