import CSS from "csstype";
import React, { useCallback } from "react";
import { userCreateTurn, UserCreateTurnInvalid } from "~src/user/apigen/createTurn";
import { IUserGetStateReturn } from "~src/user/apigen/getState";
import { userPlayAgain } from "~src/user/apigen/playAgain";
import { userTakeMiddleCards } from "~src/user/apigen/takeMiddleCards";
import { AddBot } from "./AddBot";

type OptionsProps = {
  state: IUserGetStateReturn;
  fetchState: () => void;
  setSelectedCards: (cards: number[]) => void;
  selectedCards: number[];
};

export const Options: React.FC<OptionsProps> = (
  { state, fetchState, selectedCards, setSelectedCards },
) => {
  const play = useCallback(async () => {
    if (selectedCards.length === 0) {
      return;
    }
    try {
      await userCreateTurn({ selectedCards });
    } catch (e) {
      if (!(e instanceof UserCreateTurnInvalid)) {
        alert(e);
      }
    }
    fetchState();
    setSelectedCards([]);
  }, [fetchState, selectedCards, setSelectedCards]);

  const pass = useCallback(async () => {
    try {
      await userCreateTurn({ selectedCards: [] });
    } catch (e) {
      if (!(e instanceof UserCreateTurnInvalid)) {
        alert(e);
      }
    }
    fetchState();
    setSelectedCards([]);
  }, [fetchState, setSelectedCards]);

  const takeMiddleCards = useCallback(async () => {
    await userTakeMiddleCards();
    fetchState();
  }, [fetchState]);

  const playAgain = useCallback(async () => {
    await userPlayAgain();
    fetchState();
  }, [fetchState]);

  const resetHand = useCallback(() => setSelectedCards([]), [setSelectedCards]);

  if (state.hands.length === 0) {
    return (
      <div style={waiting}>
        <div>
          waiting {state.userCount}/3
        </div>
        <AddBot state={state} />
      </div>
    );
  }

  if (state.results.length !== 0) {
    if (!state.playAgain) {
      return (
        <div style={playStyle}>
          <span onClick={playAgain}>play again</span>
        </div>
      );
    } else {
      return null;
    }
  }

  if (state.observer) {
    return null;
  }

  if ((state.position + 1) % 3 === state.turn) {
    if (state.lastTwoTurns[0] !== undefined && state.lastTwoTurns[0].length > 0) {
      return (
        <div style={playStyle}>
          <span>&uarr;</span>
        </div>
      );
    }
    if (state.lastTwoTurns[0] !== undefined && state.lastTwoTurns[0].length === 0) {
      return (
        <div style={playStyle}>
          <span>passed</span>
        </div>
      );
    }
  }

  if (state === undefined || state.position !== state.turn) {
    return null;
  }

  if (state.landlordHandID === null) {
    return (
      <div style={playStyle}>
        <span onClick={pass}>pass</span>
        <span style={tab}></span>
        <span onClick={takeMiddleCards}>take</span>
      </div>
    );
  }

  return (
    <div style={playStyle}>
      {selectedCards.length === 0
        ? <span onClick={pass}>pass</span>
        : <span onClick={resetHand}>reset</span>}
      <span style={tab}></span>
      <span onClick={play}>play</span>
    </div>
  );
};

const tab: CSS.Properties = {
  width: "20px",
};

const playStyle: CSS.Properties = {
  position: "absolute",
  bottom: "25%",
  left: "50%",
  transform: "translateX(-50%)",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "1.5em",
  cursor: "pointer",
};

const waiting: CSS.Properties = {
  position: "absolute",
  bottom: "25%",
  left: "50%",
  transform: "translateX(-50%)",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "1.5em",
  cursor: "pointer",
};