import CSS from "csstype";
import React from "react";
import { IUserGetStateReturn } from "~src/user/apigen/getState";

export type StateProps = {
  state: IUserGetStateReturn;
};

export const Center: React.FC<StateProps> = ({ state }) => {
  if (!state.gameIsActive) {
    return null;
  }

  if (state.landlordHandID === null) {
    return (
      <div style={center}>
        <span style={cardStyle}>X</span>
        <span style={cardStyle}>X</span>
        <span style={cardStyle}>X</span>
      </div>
    );
  }

  if (state.middleCards.length !== 0 && state.showMiddleCards) {
    return (
      <div style={center}>
        {state?.middleCards.map(card => {
          return (
            <span key={card.id} style={cardStyle}>
              {card.value}
            </span>
          );
        })}
      </div>
    );
  }

  for (const turn of state.lastTwoTurns) {
    if (turn.length > 0) {
      return (
        <div style={center}>
          {turn.map(card => {
            return (
              <span key={card.id} style={cardStyle}>
                {card.value}
              </span>
            );
          })}
        </div>
      );
    }
  }

  return null;
};

const cardStyle: CSS.Properties = {
  margin: "5px",
};

const center: CSS.Properties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translateX(-50%)",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "1.5em",
};
