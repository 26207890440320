import CSS from "csstype";
import React from "react";
import { StateProps } from "./Center";

export const Multiplier: React.FC<StateProps> = ({ state }) => {
  if (!state.gameIsActive) {
    return null;
  }
  return (
    <div style={multiplier(window.innerWidth < 786)}>
      <div style={header(window.innerWidth < 786)}>multiplier</div>
      x{state.multiplier}
    </div>
  );
};


const multiplier = (smallScreen: boolean): CSS.Properties => ({
  position: "absolute",
  margin: smallScreen ? "30px" : "50px",
  bottom: "0",
  left: "0",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  fontSize: "1.5em",
});

const header = (smallScreen: boolean): CSS.Properties => ({
  fontSize: ".7em",
  marginBottom: smallScreen ? "9px" : "15px",
});
