import CSS from "csstype";
import React from "react";
import { StateProps } from "./Center";

export const RightTurnSignal: React.FC<StateProps> = ({ state }) => {
  if (state.position % 3 === 2 && state.turn === 0 && state.landlordHandID === null) {
    return (
      <div style={rightTurnSignal}>
        <span>{state.shownCard.value}</span>
      </div>
    );
  }

  if ((state.position + 2) % 3 === state.turn && state.lastTwoTurns.length > 0) {
    if (state.lastTwoTurns[0] !== undefined && state.lastTwoTurns[0].length > 0) {
      return (
        <div style={rightTurnSignal}>
          <span>&larr;</span>
        </div>
      );
    }
    if (state.lastTwoTurns[0] !== undefined && state.lastTwoTurns[0].length === 0) {
      return (
        <div style={rightTurnSignal}>
          <span>pass</span>
        </div>
      );
    }
  }

  if (state.position === state.turn) {
    if (state.lastTwoTurns[0] !== undefined && state.lastTwoTurns[0].length > 0) {
      return null;
    }

    if (state.lastTwoTurns[1] !== undefined && state.lastTwoTurns[1].length > 0) {
      return (
        <div style={rightTurnSignal}>
          <span>&larr;</span>
        </div>
      );
    }
    if (state.lastTwoTurns[1] !== undefined && state.lastTwoTurns[1].length === 0) {
      return (
        <div style={rightTurnSignal}>
          <span>pass</span>
        </div>
      );
    }
  }

  return null;
};

const rightTurnSignal: CSS.Properties = {
  position: "absolute",
  top: "50%",
  right: "25%",
  transform: "translateX(-50%)",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "1.5em",
};
