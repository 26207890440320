import CSS from "csstype";
import React, { useCallback, useState } from "react";
import { Admin } from "~src/components/Admin";
import { usePublicGetUser } from "~src/public/apigen/getUser";
import { formatAmount, formatCents } from "~src/shared/helpers/format";
import { userCreateRoom, UserCreateRoomNotAllowed } from "~src/user/apigen/createRoom";
import { useUserGetBalances } from "~src/user/apigen/getBalances";
import { useUserGetRooms } from "~src/user/apigen/getRooms";
import { useUserGetStats } from "~src/user/apigen/getStats";

type HomeProps = {
  joinRoom: (s: number) => void;
  refetchUser: () => void;
};

export const Home: React.FC<HomeProps> = ({ joinRoom, refetchUser }) => {
  const [showRoomSetUp, setShowRoomSetUp] = useState(false);
  const [showAdminPage, setShowAdminPage] = useState(false);
  const { data: rooms } = useUserGetRooms();
  const [type, setType] = useState<"points" | "money">("points");
  const [base, setBase] = useState(100);
  const [maxMultiplier, setMaxMultiplier] = useState(16);

  const { data: user } = usePublicGetUser();
  const { data: balances } = useUserGetBalances();
  const { data: stats } = useUserGetStats();

  const createRoom = useCallback(async () => {
    try {
      await userCreateRoom({ type, base, maxMultiplier });
      refetchUser();
    } catch (e) {
      if (e instanceof UserCreateRoomNotAllowed) {
        alert("use discord");
      }
    }
  }, [base, maxMultiplier, refetchUser, type]);

  if (showRoomSetUp) {
    return (
      <>
        <div style={topLeft} onClick={() => setShowRoomSetUp(false)}>back</div>
        <div style={list}>
          <div style={settings}>
            play for:
            <div
              style={type === "points" ? selectedOptionStyle : optionStyle}
              onClick={() => setType("points")}
            >
              points
            </div>
            <div
              style={type === "money" ? selectedOptionStyle : optionStyle}
              onClick={() => setType("money")}
            >
              money
            </div>
          </div>
          {type === "money" && (
            <>
              <div style={settings}>
                base:
                <div
                  style={base === 25 ? selectedOptionStyle : optionStyle}
                  onClick={() => setBase(25)}
                >
                  .25
                </div>
                <div
                  style={base === 50 ? selectedOptionStyle : optionStyle}
                  onClick={() => setBase(50)}
                >
                  .5
                </div>
                <div
                  style={base === 100 ? selectedOptionStyle : optionStyle}
                  onClick={() => setBase(100)}
                >
                  1
                </div>
                <div
                  style={base === 200 ? selectedOptionStyle : optionStyle}
                  onClick={() => setBase(200)}
                >
                  2
                </div>
                <div
                  style={base === 500 ? selectedOptionStyle : optionStyle}
                  onClick={() => setBase(500)}
                >
                  5
                </div>
              </div>
              <div style={settings}>
                max mutiplier:
                <div
                  style={maxMultiplier === 4 ? selectedOptionStyle : optionStyle}
                  onClick={() => setMaxMultiplier(4)}
                >
                  4
                </div>
                <div
                  style={maxMultiplier === 8 ? selectedOptionStyle : optionStyle}
                  onClick={() => setMaxMultiplier(8)}
                >
                  8
                </div>
                <div
                  style={maxMultiplier === 16 ? selectedOptionStyle : optionStyle}
                  onClick={() => setMaxMultiplier(16)}
                >
                  16
                </div>
                <div
                  style={maxMultiplier === 32 ? selectedOptionStyle : optionStyle}
                  onClick={() => setMaxMultiplier(32)}
                >
                  32
                </div>
                <div
                  style={maxMultiplier === 64 ? selectedOptionStyle : optionStyle}
                  onClick={() => setMaxMultiplier(64)}
                >
                  64
                </div>
              </div>
            </>
          )}
          <div style={optionStyle} onClick={createRoom}>create room</div>
        </div>
      </>
    );
  }

  if (showAdminPage) {
    return <Admin setShowAdminPage={setShowAdminPage} />;
  }

  return (
    <>
      <div style={topLeft} onClick={() => setShowRoomSetUp(true)}>
      <div style={roomStyle} >
        create room
      </div>
        {rooms?.rooms.map(room => {
          return (
            <div style={roomStyle} key={room.id} onClick={() => joinRoom(room.id)}>
              room: {room.id}{" - "}{room.userCount}/3
              {room.type === "money" && (
                <div>base: {formatAmount(room.baseAmount)} max: x{room.maxMultiplier}</div>
              )}
            </div>
          );
        })}
      </div>
      {stats !== undefined && (
      <div style={list}>
        Stats
        <div style={row}>
          <div style={leftCol}>games:</div><div style={rightCol}>{stats.gamesPlayed}</div> 
        </div>
        <div style={row}>
          <div style={leftCol}>wins:</div><div style={rightCol}>{stats.gamesWon}</div> 
        </div>
        <div style={row}>
          <div style={leftCol}>ppg:</div><div style={rightCol}>{stats.pointsPerGame/100}</div> 
        </div>
        <div style={row}>
          <div style={leftCol}>bombs:</div><div style={rightCol}>{stats.bombs}</div> 
        </div>
      </div>
      )}
      <div style={topRight}>
        {user !== undefined && user.type === "admin" && (
          <span
            style={admin}
            onClick={() => setShowAdminPage(true)}
          >
            admin /
          </span>
        )}
        balances
      </div>
      <div style={balancesStyle}>
      {balances?.balances.map(balance => {
          return (
            <div key={balance.id}>
              {balance.displayName}: {formatCents(balance.amount, "usd")}
            </div>
          );
        })}
      </div>
    </>
  );
};

const list: CSS.Properties = {
  position: "absolute",
  marginTop: "25px",
  minWidth: '15%',
  left: "50%",
  transform: "translateX(-50%)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const optionStyle: CSS.Properties = {
  marginLeft: "10px",
  cursor: "pointer",
};

const selectedOptionStyle: CSS.Properties = {
  ...optionStyle,
  color: "red",
};

const row: CSS.Properties = {
  display: "flex",
  flexDirection: "row",
  width: "100%",
};

const settings: CSS.Properties = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginBottom: "15px",
};

const topLeft: CSS.Properties = {
  position: "absolute",
  top: "25px",
  left: "25px",
  cursor: "pointer",
};

const roomStyle: CSS.Properties = {
  cursor: "pointer",
  marginBottom: "10px",
};

const topRight: CSS.Properties = {
  position: "absolute",
  top: "25px",
  right: "25px",
};

const admin: CSS.Properties = {
  marginRight: "15px",
  cursor: "pointer",
};

const balancesStyle: CSS.Properties = {
  position: "absolute",
  top: "75px",
  right: "25px",
  textAlign: "right",
};

const leftCol: CSS.Properties = {
  position: "relative",
  width: "50%",
  left: 0,
  textAlign: "left",
  marginRight: "8px",
};

const rightCol: CSS.Properties = {
  position: "relative",
  width: "50%",
  textAlign: "right",
  marginLeft: "8px",
};
