// Code generated by go run pkg/apigen/generate. DO NOT EDIT.

import useSWR, { SWRConfiguration, SWRResponse } from "swr";

import * as enums from "~src/shared/enums";
import * as routerTypes from "~src/user/apigen/types";
import * as globalTypes from "~src/shared/apigen/types";
import { fetchRPCRoute } from "~src/shared/apigen/framework";
import { FieldValidationError, RPCError, IRPCErrorOptions } from "~src/shared/errors/taxonomy";

export type IGetStateCard = {
  id: number;
  rank: number;
  value: string;
};

export type IGetStateHand = {
  displayName: string;
  position: number;
  handID: number;
  roomBalance: number;
  cardsLeft: number | null;
  dou: boolean;
};

export type IGetStateResults = {
  displayName: string;
  change: number;
};

export type IUserGetStateReturn = {
  gameIsActive: boolean;
  observer: boolean;
  cards: IGetStateCard[];
  middleCards: IGetStateCard[];
  position: number;
  lastTwoTurns: IGetStateCard[][];
  turn: number;
  showMiddleCards: boolean;
  shownCard: IGetStateCard;
  landlordHandID: number | null;
  playAgain: boolean;
  multiplier: number;
  userCount: number;
  results: IGetStateResults[];
  hands: IGetStateHand[];
};

export const userGetState = async (
): Promise<IUserGetStateReturn> => {
  return await fetchRPCRoute({
    method: "POST",
    router: "user",
    routePath: "GetState",
    args: undefined,
    errors: [
    ],
  });
};

export const useUserGetState = (
  config?: SWRConfiguration & {
    // If true, the hook will not fetch. Data will be null.
    blockFetch?: boolean,
    // This value will be included in the cache key. Pass different values to force cache
    // invalidation.
    refetchNonce?: number | string,
  },
): SWRResponse<IUserGetStateReturn, RPCError> => {
  return useSWR(
    config?.blockFetch === true ? null : ["/user/GetState", config?.refetchNonce],
    userGetState,
    config,
  );
};
