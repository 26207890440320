const SUFFIXES: Record<string, number> = {
  T: 1e12,
  B: 1e9,
  M: 1e6,
  K: 1e3,
  "": 1,
};

// https://stackoverflow.com/questions/19373860/convert-currency-names-to-currency-symbol
export const currencySymbol = (currency: string): string => {
  const dummy = 0;
  return dummy.toLocaleString("en", { style: "currency", currency: currency.toUpperCase() })
    .replace(/\d+([,.]\d+)?/g, "");
};

export const formatBPS = (bps: number | null): string => {
  if (bps === null) {
    return "-";
  }
  return formatPercent(bps * 0.0001);
};

export const formatCentsShort = (
  cents: number,
  currency: string,
  opts?: {
    lowercase?: boolean;
  },
): React.ReactNode => {
  const dollars = cents / 100;
  return (
    Object.entries(SUFFIXES).map(([suffix, threshold]): string | null => {
      if (dollars >= threshold) {
        const numPart = dollars / threshold;
        const str = `$${
          numPart.toLocaleString("en-US", {
            minimumSignificantDigits: 3,
            maximumSignificantDigits: 3,
          })
        }${suffix}`;
        return opts?.lowercase === true ? str.toLowerCase() : str;
      }
      return null;
    }).find(x => x != null) ?? formatCents(cents, currency, { omitCents: true })
  );
};

/** takes a number and divides it by 100! */
export const formatCentsToDollars = (value: number | null): number | null => {
  return value === null ? null : value / 100;
};

export const formatCentsStr = (
  cents: number | null,
  currency: string,
  {
    format = "accounting",
    omitCents = false,
    invertSign = false,
    siSuffix = false,
  }: IFormatCentsArgs = {},
): string => {
  if (Number.isNaN(cents) || cents === null) {
    return "N/A";
  }

  // Handle SI units
  let magnitudeOffset = 0;
  if (siSuffix && Math.abs(cents) > 1) {
    const threes = Math.floor((Math.log10(Math.abs(cents)) - 2) / 3);
    if (threes > 0) {
      // zeroes to offset
      magnitudeOffset = threes * 3 + 2;
    }
  }

  const centsWithSign = (invertSign === true ? -1 : 1) * cents;
  const centsWithOffset = magnitudeOffset > 0
    ? centsWithSign / 10 ** (magnitudeOffset - 2)
    : centsWithSign;
  const amount = (format === "accounting" ? Math.abs(centsWithOffset) : centsWithOffset) / 100;
  let amountStr = amount.toLocaleString("en-US", {
    style: "currency",
    // TODO(md): Hack to make this not break. Have a better story for this in the
    // upcoming currency work.
    currency: currency.toLowerCase() === "unknown" ? "USD" : currency.toUpperCase(),
    ...(omitCents === true && {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }),
    ...(magnitudeOffset !== 0 && {
      maximumFractionDigits: 3,
      maximumSignificantDigits: 3,
    }),
  }) + (magnitudeOffset > 0 ? renderOffset(magnitudeOffset) : "");
  // TODO(md): HACK lol
  if (currency === "unknown") {
    amountStr = amountStr.replace("$", "");
  }

  return format === "accounting" && centsWithSign < 0 ? `(${amountStr})` : amountStr;
};

const renderOffset = (offset: number): string => {
  switch (offset) {
    case 0:
      return "";
    case 5:
      return "k";
    case 8:
      return "m";
    case 11:
      return "b";
    case 14:
      return "t";
    case 17:
      return "q";
    default:
      return "?";
  }
};

// NB: This song and dance is needed to format money up to 4-decimal places.
export const formatBidPrice = (bidPrice: number, currency: string, decimal = 4): string => {
  const s = formatCents(100, currency, { omitCents: true });
  return s.replace("1", `${bidPrice.toFixed(decimal)}`);
};

// TODO: Combine these into one? Two for one deals are not accepted!
// TODO: make currency a required parameter.
export const formatCents = formatCentsStr;

export const formatCentsNoCurrency = (amount: number) => {
  const str = amount.toString();
  let formattedStr = "";
  for (let i = 0; i < str.length; i += 1) {
    formattedStr += str[i];
    if (str.length - i === 3) {
      formattedStr += ".";
    } else if (str.length - i > 5 && (str.length - i) % 3 === 0) {
      formattedStr += ",";
    }
  }
  return formattedStr;
};

interface IFormatCentsArgs {
  /**
   * Omits cents from the returned value
   */
  omitCents?: boolean;
  /**
   * Accounting -- parentheses for negative values
   * Currency -- negative numbers are $-100,000
   */
  format?: "accounting" | "currency";
  /**
   * If true, the sign is inverted
   */
  invertSign?: boolean;
  /**
   * If true, uses the SI suffix (k/m/b) if possible.
   */
  siSuffix?: boolean;
}

/**
 * Formats money
 * @param amount Amount in currency (base currency, not cents)
 * @param currency currency; defaults to usd
 */
export const formatMoney = (amount: number | null, currency?: "usd"): string => {
  if (Number.isNaN(amount) || amount === null) {
    return "N/A";
  }
  switch (currency) {
    case "usd":
      return "$" + amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    default:
      return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  }
};

/**
 * Formats a phone number with parentheses etc.
 * @param phone
 */
export const formatPhone = (phone: string): string => {
  return phone.replace(/[^\d]+/g, "").replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
};

/**
 * Formats a date string according to its UTC date.
 * E.g. 12/1/12
 * @param date
 */
export const formatDate = (date?: string | null): string =>
  date
    ? new Date(date).toLocaleDateString("en-US", {
      timeZone: "UTC",
    })
    : "-";

/**
 * Formats a date as month + day
 * E.g. Oct 1
 * @param date
 */
export const formatMonthDay = (date?: string | null): string =>
  date
    ? new Date(date).toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    })
    : "--";

/**
 * Formats a date as month + day
 * E.g. Oct 1, 2002
 * @param date
 */
export const formatMonthDayYear = (date?: string | null): string =>
  date
    ? new Date(date).toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    })
    : "--";

export const formatMonthYear = (date?: string | null): string =>
  date
    ? new Date(date).toLocaleDateString("en-US", {
      month: "short",
      year: "numeric",
    })
    : "--";

/**
 * Formats a date string with a 2 digit year according to its UTC date.
 * E.g. 12/01/12
 * @param date
 */
export const formatDateShortYear = (date?: string | null): string =>
  date
    ? new Date(date).toLocaleDateString("en-US", {
      timeZone: "UTC",
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    })
    : "-";

/**
 * Formats date + time without seconds.
 * @deprecated Use Format.Date
 */
export const formatTime = (
  time?: string | null,
  options: Intl.DateTimeFormatOptions = {},
): string => {
  if (typeof window === "undefined") {
    // server side should default to LA time
    options.timeZone = "America/Los_Angeles";
  }
  return time
    ? new Date(time).toLocaleString("en-US", options).replace(/:\d{2}\s/, " ")
    : "-";
};

export const formatBoolean = (bool: boolean): string =>
  typeof bool === "boolean" ? (bool ? "Yes" : "No") : "--";

/**
 * Formats an integer with commas, e.g. 1,234 or 456
 * @param number
 */
export const formatInteger = (number: number): string =>
  number.toLocaleString("en-US", { maximumFractionDigits: 0 });

/**
 * Take in a decimal representation and outputs the equivalent percentage with percent symbol.
 * Ex. formatPercent(.98) ==> "98%"
 */
export const formatPercent = (number: number): string => (number * 100).toFixed(2) + "%";

export const decimalToPercent = (number: number | null | undefined): number | undefined => {
  if (number === null || number === undefined) {
    return undefined;
  }
  return number * 100;
};

export const formatPercentWhole = (number: number | null): string =>
  typeof number === "number" ? (number * 100).toFixed(0) + "%" : "--%";

export const formatPeriod = ({
  value,
  interval_unit,
}: {
  value: number | null;
  /** Singular form of the interval unit. Ex. "month". */
  interval_unit: string | null;
}): string => {
  const valueNum = value === null ? 1 : value;
  const intervalStr = interval_unit ?? "period";
  return intervalStr + (valueNum === 1 ? "" : "s");
};

export const formatTimeOfDay = (date: Date): string => {
  const hour = date.getHours();
  if (hour > 6 && hour < 12) {
    return "Morning";
  }
  if (hour < 18) {
    return "Afternoon";
  }
  return "Evening";

  // good night seems kinda rude tbh
  // true...
};

export const formatValueByType = (
  value: number | null | undefined,
  type: "currency" | "percent" | "none" | undefined,
): number | undefined => {
  return (
    (type === "currency"
      ? formatCentsToDollars(value ?? null)
      : type === "percent"
      ? decimalToPercent(value ?? null)
      : value) ?? undefined
  );
};

export function formatAmount(value: number): string {
  const decimalValue = value / 100;
  return decimalValue < 1 && decimalValue > 0
    ? "." + decimalValue.toString().split(".")[1]
    : decimalValue.toString();
}
