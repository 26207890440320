// Code generated by go run pkg/apigen/generate. DO NOT EDIT.

import useSWR, { SWRConfiguration, SWRResponse } from "swr";

import * as enums from "~src/shared/enums";
import * as routerTypes from "~src/user/apigen/types";
import * as globalTypes from "~src/shared/apigen/types";
import { fetchRPCRoute } from "~src/shared/apigen/framework";
import { FieldValidationError, RPCError, IRPCErrorOptions } from "~src/shared/errors/taxonomy";

export type IUserGetMessagesRequest = {
  afterID: number | null;
};

export type IUserGetMessagesReturn = {
  messages: globalTypes.IMessage[];
};

export const userGetMessages = async (
  args: IUserGetMessagesRequest,
): Promise<IUserGetMessagesReturn> => {
  return await fetchRPCRoute({
    method: "POST",
    router: "user",
    routePath: "GetMessages",
    args,
    errors: [
    ],
  });
};

export const useUserGetMessages = (
  args: IUserGetMessagesRequest,
  config?: SWRConfiguration & {
    // If true, the hook will not fetch. Data will be null.
    blockFetch?: boolean,
    // This value will be included in the cache key. Pass different values to force cache
    // invalidation.
    refetchNonce?: number | string,
  },
): SWRResponse<IUserGetMessagesReturn, RPCError> => {
  return useSWR(
    config?.blockFetch === true ? null : ["/user/GetMessages", args, config?.refetchNonce],
    async (_, _args) => await userGetMessages(_args),
    config,
  );
};
