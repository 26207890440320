import CSS from "csstype";
import React, { useCallback } from "react";
import { userAddBot } from "~src/user/apigen/addBot";
import { useUserGetState } from "~src/user/apigen/getState";
import { StateProps } from "./Center";

export const AddBot: React.FC<StateProps> = ({ state }) => {
  const { mutate: refetchState } = useUserGetState();

  const addBot = useCallback(async () => {
    await userAddBot();
    refetchState();
  }, [refetchState]);

  if (state.hands.length !== 0) {
    return null;
  }

  return (
    <div style={addBotStyle} onClick={addBot}>
      add bot
    </div>
  );
};

const addBotStyle: CSS.Properties = {
  position: "relative",
  // top: "25px",
  // right: "25px",
  textAlign: "center",
  cursor: "pointer",
};
