import CSS from "csstype";
import React from "react";
import { formatAmount } from "~src/shared/helpers/format";
import { IGetStateResults } from "~src/user/apigen/getState";
import { StateProps } from "./Center";

export const Results: React.FC<StateProps> = ({ state }) => {
  return (
    <div style={results}>
      {state.results.map((result: IGetStateResults) => {
        return (
          <div style={row}>
            <div style={nameStyle}>{result.displayName}:</div>
            <div style={changeStyle}>
              {result.change > 0 ? "+" : ""}
              {formatAmount(result.change)}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const row: CSS.Properties = {
  display: "flex",
  flexDirection: "row",
};

const results: CSS.Properties = {
  position: "absolute",
  top: "25%",
  left: "50%",
  transform: "translateX(-50%)",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "1.5em",
  width: "80%",
};

const nameStyle: CSS.Properties = {
  position: "relative",
  width: "50%",
  textAlign: "right",
  marginRight: "8px",
};

const changeStyle: CSS.Properties = {
  position: "relative",
  width: "50%",
  textAlign: "left",
  marginLeft: "8px",
};
