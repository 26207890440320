// Code generated by go run pkg/apigen/generate. DO NOT EDIT.

import useSWR, { SWRConfiguration, SWRResponse } from "swr";

import * as enums from "~src/shared/enums";
import * as routerTypes from "~src/admin/apigen/types";
import * as globalTypes from "~src/shared/apigen/types";
import { fetchRPCRoute } from "~src/shared/apigen/framework";
import { FieldValidationError, RPCError, IRPCErrorOptions } from "~src/shared/errors/taxonomy";

export type IGetUsersUser = {
  id: number;
  username: string | null;
  displayName: string;
  balance: number;
};

export type IAdminGetUsersReturn = {
  users: IGetUsersUser[];
};

export const adminGetUsers = async (
): Promise<IAdminGetUsersReturn> => {
  return await fetchRPCRoute({
    method: "POST",
    router: "admin",
    routePath: "GetUsers",
    args: undefined,
    errors: [
    ],
  });
};

export const useAdminGetUsers = (
  config?: SWRConfiguration & {
    // If true, the hook will not fetch. Data will be null.
    blockFetch?: boolean,
    // This value will be included in the cache key. Pass different values to force cache
    // invalidation.
    refetchNonce?: number | string,
  },
): SWRResponse<IAdminGetUsersReturn, RPCError> => {
  return useSWR(
    config?.blockFetch === true ? null : ["/admin/GetUsers", config?.refetchNonce],
    adminGetUsers,
    config,
  );
};
