import React, { useCallback } from "react";
import { Home } from "./pages/Home";
import { Room } from "./pages/Room";
import { SignIn } from "./pages/SignIn";
import { usePublicGetUser } from "./public/apigen/getUser";
import { userJoinRoom } from "./user/apigen/joinRoom";
import { userLeaveRoom } from "./user/apigen/leaveRoom";

function App() {
  const props = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  };

  const { data: user, isValidating: userIsValidating, mutate: refetchUser } = usePublicGetUser({
    ...props,
  });

  const joinRoom = useCallback(async (roomID: number) => {
    await userJoinRoom({ roomID });
    refetchUser();
  }, [refetchUser]);

  const leaveRoom = useCallback(async () => {
    await userLeaveRoom();
    refetchUser();
  }, [refetchUser]);

  if (userIsValidating || user === undefined) {
    return null;
  }

  if (user.id === null) {
    return <SignIn refetchUser={refetchUser} />;
  }
  if (user.roomID === null) {
    return <Home joinRoom={joinRoom} refetchUser={refetchUser} />;
  }
  return <Room leaveRoom={leaveRoom} />;
}

export default App;
