import CSS from "csstype";
import React, { useCallback, useEffect, useState } from "react";
import { Balance } from "~src/components/Balance";
import { Center } from "~src/components/Center";
import { Chat } from "~src/components/Chat";
import { Hand } from "~src/components/Hand";
import { LeftPerson } from "~src/components/LeftPerson";
import { LeftTurnSignal } from "~src/components/LeftTurnSignal";
import { Multiplier } from "~src/components/Multiplier";
import { Options } from "~src/components/Options";
import { Results } from "~src/components/Results";
import { RightPerson } from "~src/components/RightPerson";
import { RightTurnSignal } from "~src/components/RightTurnSignal";
import { useUserCheckState } from "~src/user/apigen/checkState";
import { useUserGetState } from "~src/user/apigen/getState";

type RoomProps = {
  leaveRoom: () => void;
};

export const Room: React.FC<RoomProps> = ({ leaveRoom }) => {
  const [selectedCards, setSelectedCards] = useState<number[]>([]);
  const { data: state, mutate: fetchState } = useUserGetState();
  const { data: stateNumber, mutate: checkState } = useUserCheckState();
  const [chatOpen, setChatOpen] = useState<boolean>(false);


  const toggleCard = useCallback((selectedID: number) => {
    let updatedCards;

    if (selectedCards.includes(selectedID)) {
      updatedCards = selectedCards.filter(id => id !== selectedID);
    } else {
      updatedCards = [...selectedCards, selectedID];
    }

    setSelectedCards(updatedCards);
  }, [selectedCards]);

  useEffect(() => {
    const interval = setInterval(() => {
      checkState();
    }, 1000);
    return () => clearInterval(interval);
  }, [checkState, fetchState]);

  useEffect(() => {
    fetchState();
  }, [fetchState, stateNumber]);


  if (state === undefined) {
    return null;
  }

  return (
    <>
      <div style={roomStyle(chatOpen)}>
        <div style={leave(window.innerWidth < 786)} onClick={leaveRoom}>&larr;</div>
        <Options
          state={state}
          fetchState={fetchState}
          selectedCards={selectedCards}
          setSelectedCards={setSelectedCards}
        />
        <Center state={state} />
        <Results state={state} />
        <LeftPerson state={state} />
        <RightPerson state={state} />
        <LeftTurnSignal state={state} />
        <RightTurnSignal state={state} />
        <Multiplier state={state} />
        <Balance state={state} />
        <Hand state={state} selectedCards={selectedCards} toggleCard={toggleCard} />
        {!chatOpen && (
            <div>
              <div role="button" onClick={() => setChatOpen(true)} style={openChat(window.innerWidth < 786)}>
                {"<<"} chat
              </div>
            </div>
          )}
        <div>
          
        </div>
      </div>
      <Chat chatOpen={chatOpen} setChatOpen={setChatOpen} />
    </>
  );
};

const leave = (smallScreen: boolean): CSS.Properties => ({
  position: "absolute",
  top: 0,
  left: 0,
  marginLeft: smallScreen ? "30px" : "50px",
  marginTop: smallScreen ? "20px" : "30px",
  fontSize: "2em",
  cursor: "pointer",
});



const roomStyle = (showChat: boolean): CSS.Properties => ({
  position: "absolute",
  width: showChat ? "80%": "100%",
  height: "100%",
});

const openChat = (smallScreen: boolean): CSS.Properties => ({
  position: "absolute",
  top: 0,
  right: 0,
  margin: smallScreen ? "30px" : "50px",
  // marginTop: smallScreen ? "16px" : "40px",
  cursor: "pointer",
});
