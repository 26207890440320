import CSS from "csstype";
import React, { useCallback, useState } from "react";
import { adminAddLedgerEntry } from "~src/admin/apigen/addLedgerEntry";
import { useAdminGetUsers } from "~src/admin/apigen/getUsers";
import { formatCents } from "~src/shared/helpers/format";

export type AdminProps = {
  setShowAdminPage: (f: boolean) => void;
};

export const Admin: React.FC<AdminProps> = ({ setShowAdminPage }) => {
  const [showAddLedgerEntry, setShowAddLedgerEntry] = useState(false);
  const [userID, setUserID] = useState<number>();
  const [amount, setAmount] = useState<number>();
  const { data, mutate: refetchUsers } = useAdminGetUsers();

  const showForm = useCallback((id: number) => {
    setShowAddLedgerEntry(true);
    setUserID(id);
  }, []);

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(parseInt(e.target.value, 10));
  };

  const addLedgerEntry = useCallback(async () => {
    if (userID === undefined || amount === undefined) {
      return;
    }
    await adminAddLedgerEntry({ userID, amount });
    setShowAddLedgerEntry(false);
    refetchUsers();
  }, [amount, refetchUsers, userID]);

  if (data === undefined) {
    return null;
  }

  return (
    <>
      <div style={topLeftButton} onClick={() => setShowAdminPage(false)}>back</div>
      <div style={table}>
        <div style={row}>
          <div style={col1}>ID</div>
          <div style={col2}>Username</div>
          <div style={col3}>Display Name</div>
          <div style={col4}>Balance</div>
          <div style={col5}>Add</div>
        </div>
        {data.users.map((user, rowNum) => {
          return (
            <div key={user.id} style={rowNum % 2 === 1 ? row : darkRow}>
              <div style={col1}>{user.id}</div>
              <div style={col2}>{user.username}</div>
              <div style={col3}>{user.displayName}</div>
              <div style={col4}>{formatCents(user.balance, "usd")}</div>
              <div
                style={col5}
                onClick={() => {
                  showForm(user.id);
                }}
              >
                +
              </div>
            </div>
          );
        })}
      </div>
      {showAddLedgerEntry && (
        <div style={form}>
          add payment for user {userID}
          <input style={inputStyle} type="number" onChange={handleAmountChange} />
          <span style={addStyle} onClick={addLedgerEntry}>add</span>
        </div>
      )}
    </>
  );
};

const inputStyle: CSS.Properties = {
  width: "10%",
  marginLeft: "20px",
  transform: "translateY(-2px)",
  backgroundColor: "#181818",
  color: "#bbb",
};

const addStyle: CSS.Properties = {
  marginLeft: "20px",
  cursor: "pointer",
};

const col1: CSS.Properties = {
  width: "10%",
  paddingLeft: "3%",
};

const col2: CSS.Properties = {
  width: "45%",
};

const col3: CSS.Properties = {
  textAlign: "left",
  width: "20%",
};

const col4: CSS.Properties = {
  width: "15%",
  textAlign: "right",
};

const col5: CSS.Properties = {
  width: "10%",
  textAlign: "center",
  cursor: "pointer",
};

const row: CSS.Properties = {
  display: "flex",
  padding: "3px",
  flexDirection: "row",
};

const darkRow: CSS.Properties = {
  ...row,
  backgroundColor: "#111",
};

const table: CSS.Properties = {
  position: "absolute",
  top: "75px",
  left: "50%",
  transform: "translateX(-50%)",
  fontSize: ".7em",
  width: "50%",
};

const form: CSS.Properties = {
  position: "absolute",
  top: "25px",
  left: "50%",
  transform: "translateX(-50%)",
  fontSize: "1em",
  width: "50%",
};

const topLeftButton: CSS.Properties = {
  position: "absolute",
  top: "25px",
  left: "25px",
  cursor: "pointer",
  fontFamily: "ChineseTakeaway",
};
