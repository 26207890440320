// Code generated by go run pkg/apigen/generate. DO NOT EDIT.

import useSWR, { SWRConfiguration, SWRResponse } from "swr";

import * as enums from "~src/shared/enums";
import * as routerTypes from "~src/user/apigen/types";
import * as globalTypes from "~src/shared/apigen/types";
import { fetchRPCRoute } from "~src/shared/apigen/framework";
import { FieldValidationError, RPCError, IRPCErrorOptions } from "~src/shared/errors/taxonomy";

export type IUserSendMessageRequest = {
  contents: string;
  lastSeenMessageID: number | null;
};

export type IUserSendMessageReturn = {
  newMessages: globalTypes.IMessage[];
};

export const userSendMessage = async (
  args: IUserSendMessageRequest,
): Promise<IUserSendMessageReturn> => {
  return await fetchRPCRoute({
    method: "POST",
    router: "user",
    routePath: "SendMessage",
    args,
    errors: [
    ],
  });
};

export const useUserSendMessage = (
  args: IUserSendMessageRequest,
  config?: SWRConfiguration & {
    // If true, the hook will not fetch. Data will be null.
    blockFetch?: boolean,
    // This value will be included in the cache key. Pass different values to force cache
    // invalidation.
    refetchNonce?: number | string,
  },
): SWRResponse<IUserSendMessageReturn, RPCError> => {
  return useSWR(
    config?.blockFetch === true ? null : ["/user/SendMessage", args, config?.refetchNonce],
    async (_, _args) => await userSendMessage(_args),
    config,
  );
};
